import React, { useRef, useEffect } from "react";
import SEO from "../../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap";


const ProcessLines = ({ intl }) => {
	const data = useStaticQuery(graphql`{
  processLinesBackground: file(
    relativePath: {eq: "machines/processLines/processLinesBackground.jpg"}
  ) {
    childImageSharp {
      original {
        src
      }
    }
  }
  processLinesBackgroundMobile: file(
    relativePath: {eq: "machines/processLines/processLinesBackgroundMobile.jpg"}
  ) {
    childImageSharp {
      original {
        src
      }
    }
  }
  processLinesBenefitIcon: file(
    relativePath: {eq: "machines/processLines/processLinesBenefitIcon.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 135, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  allProcessLinesDataJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        title
        goTo
      }
    }
  }
  allProcessLinesProductListJson {
    edges {
      node {
        product
      }
    }
  }
  allProcessLinesToDoListJson {
    edges {
      node {
        toDo
      }
    }
  }
  allProcessLinesBenefitsListJson {
    edges {
      node {
        benefit
      }
    }
  }
}
`);
  let processLinesHome = useRef(null);
	let gatsbyBackgroundImage = useRef(null);
	let gatsbyBackgroundImageMobile = useRef(null);
	let gatsbyBackgroundImageText = useRef(null);



	useEffect(() => {
		gsap.from(processLinesHome.current, { duration: 2, autoAlpha: 0 });
    gsap.fromTo(gatsbyBackgroundImage.current, {opacity: 0}, {duration: 2, opacity: 1})
    gsap.fromTo(gatsbyBackgroundImageMobile.current, {opacity: 0 }, {duration: 2, opacity: 1})
    gsap.fromTo(gatsbyBackgroundImageText.current, {opacity: 0, y: 50}, {duration: .75, opacity: 1, y: 0, delay: 1.5})


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processLinesHome]);
	return <>
        <SEO
            title={intl.formatMessage({ id: "machineSelection.processLines" })}
        />

        <div className="processLinesPage">
            <div className="processLinesHome" ref={processLinesHome}>
                <img
                    ref={gatsbyBackgroundImage}
										src={data.processLinesBackground.childImageSharp.original.src}
                    className="gatsbyBackgroundImage"
                    alt="BackgroundImage"
                     />
                <img
                    ref={gatsbyBackgroundImageMobile}
										src={data.processLinesBackgroundMobile.childImageSharp.original.src}
                    className="gatsbyBackgroundImageMobile"
                    alt="BackgroundImage"
                    />
                <div className="processLinesTitleBox">
                    <p className="processLinesOverlayTitle" ref={gatsbyBackgroundImageText}>
                        {intl.formatMessage({ id: "processLines.name" })}
                    </p>
                </div>
                <div className="processLinesEmptyDarkBox"></div>
                <div className="processLinesBottomText">
                    <p>{intl.formatMessage({ id: "about.subTitle" })}</p>
                </div>
            </div>

            <div className="processLinesSectionAbout01">
                <p>{intl.formatMessage({ id: "processLines.text02" })}</p>
                <div className="processLinesSectionAbout01ListBox">
                    <div className="processLinesAbout01ListRow">
                        {data.allProcessLinesProductListJson.edges.filter((item,index) => index < 8).map((item, index) => {
                            return (
                                <li className="processLinesList" key={index}>
                                    <FormattedMessage id={item.node.product} />
                                </li>
                            );
                        })}
                    </div>
                    <div className="processLinesAbout01ListRow">
                        {data.allProcessLinesProductListJson.edges.filter((item,index) => index > 7).map((item, index) => {
                            return (
                                <li className="processLinesList" key={index}>
                                    <FormattedMessage id={item.node.product} />
                                </li>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="processLinesSectionCards">
                <div className="processLinesCardsWrapper">
                    {data.allProcessLinesDataJson.edges.map((item, index) => {
                        return (
                            <Link to={item.node.goTo} key={index}>
                                <div className="processLinesCards">
                                    <div className="processLinesCardImageBox">
                                        <GatsbyImage
                                            image={item.node.image.childImageSharp.gatsbyImageData}
                                            className="processLinesCardImage"
                                            alt="processLinesImages"
                                            loading="eager" />
                                    </div>
                                    <div className="processLinesCardTextDiv">
                                        <div className="processLinesCardText">
                                            <p>
                                                <FormattedMessage id={item.node.title} />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="processLinesCardBottomLine"></div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>

            <div className="processLinesSectionAbout02">
                <p>{intl.formatMessage({ id: "processLines.compatible" })}</p>
                <div className="processLinesSectionAbout01ListBox">
                    <div className="processLinesAbout02ListRow">
                        {data.allProcessLinesToDoListJson.edges.filter((item,index) => index < 4).map((item, index) => {
                            return (
                                <li className="processLinesList" key={index}>
                                    <FormattedMessage id={item.node.toDo} />
                                </li>
                            );
                        })}
                    </div>
                    <div className="processLinesAbout02ListRow">
                        {data.allProcessLinesToDoListJson.edges.filter((item,index) => index > 3).map((item, index) => {
                            return (
                                <li className="processLinesList" key={index}>
                                    <FormattedMessage id={item.node.toDo} />
                                </li>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="processLinesSectionAbout03">
                <p>{intl.formatMessage({ id: "processLines.text01" })}</p>
            </div>

            <div className="processLinesSectionAbout04">
                <div className="recenteredBox">
                    <GatsbyImage
                        image={data.processLinesBenefitIcon.childImageSharp.gatsbyImageData}
                        className="processLinesBenefitIcon"
                        alt="processLinesBenefitIcon"
                        loading="eager" />
                    <p>{intl.formatMessage({ id: "processLines.benefits" })}</p>
                    <div className="processLinesAbout04ListRow">
                        {data.allProcessLinesBenefitsListJson.edges.map((item, index) => {
                            return (
                                <li className="processLinesList" key={index}>
                                    <FormattedMessage id={item.node.benefit} />
                                </li>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default injectIntl(ProcessLines);
